<template>
  <div class="container">
    <div class="title"><span @click="previousPage()">&lt;</span>修改手机号</div>
    <div class="content">
      <div class="up-li">
        <span>新手机号：</span>
        <input
          v-model="phone"
          class="up-input"
          type="number"
          placeholder-style="color:#ddd;font-size:3.2vw;margin-left:4.6vw;"
          placeholder="请输入11位的手机号"
        >
      </div>
      <span
        v-if="phone_span_show"
        id="phone_span"
        v-text="phone_span_value"
      />

      <div class="up-li">
        <span>验&nbsp;&nbsp;证&nbsp;&nbsp;码：</span>
        <input
          v-model="code"
          class="up-input"
          type="text"
          placeholder="短信验证码"
          placeholder-style="color:#ddd;font-size:3.2vw;margin-left:4.6vw;"
        >
        <hr class="up-hr">
        <span
          v-if="yz_btn_show"
          class="up-span"
          @click="getmsg()"
        >获取验证码</span>
        <span v-if="!yz_btn_show" class="yz_text">{{ time }}</span>
      </div>
    </div>
    <div class="footer">
      <span class="end" @click="wc()">确&nbsp;认</span>
    </div>
  </div>
</template>

<script>
/*
 *api
 */
import { checkSmsForUpdateMobile, sendSmsForUpdateMobile } from '../../../api';
import { isPhone } from '../../../util/validate';
export default {
  data() {
    return {
      phone: '',
      code: '',
      yz_btn_show: true,
      phoneshow: false,
      time: 60,
      phone_span_value: '请先输入手机号',
      phone_span_show: false
    };
  },
  updated() {
    this.phone = this.phone > 11 ? this.phone.slice(0, 11) : this.phone;
  },
  methods: {
    previousPage() {
      history.go(-1);
    },
    getmsg() {
      if (this.phone == '') {
        this.phone_span_value = '请先输入手机号';
        this.phone_span_show = true;
        setTimeout(() => {
          this.phone_span_show = false;
        }, 3000);
      } else {
        if (isPhone(this.phone)) {
          sendSmsForUpdateMobile({
            mobileNumber: this.phone
          })
            .then((res) => {
              if (res.data.code === 200) {
                this.yz_btn_show = false;
                this.yz_text_show = true;
                this.phone_span_show = false;
                setInterval(() => {
                  this.time--;
                  if (this.time < 0) {
                    this.yz_btn_show = true;
                    this.yz_text_show = false;
                    this.time = 60;
                    clearInterval();
                  }
                }, 1000);
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
              console.log(err);
              if (err.response) {
                let loginUrl = err.response.headers.loginurl;
                console.log('loginUrl' + loginUrl);
                if (loginUrl) {
                  let backUrl = window.location.hash;
                  let localBaseIndexOf = loginUrl.indexOf('redirect_uri=');
                  if (localBaseIndexOf != -1) {
                    let localBase = loginUrl.substring(
                      localBaseIndexOf + 13,
                      loginUrl.indexOf('oauth2-login')
                    );
                    backUrl = window.location.href.replace(localBase, '');
                  }
                  // 跳转到系统登录页面
                  // debugger
                  console.log(
                    loginUrl +
                      '?backUrl=' +
                      encodeURIComponent(encodeURIComponent(backUrl))
                  );
                  window.location.href =
                    loginUrl +
                    '?backUrl=' +
                    encodeURIComponent(encodeURIComponent(backUrl));
                }
              }
            });
        } else {
          this.phone_span_value = '手机号格式错误';
          this.phone_span_show = true;
          setTimeout(() => {
            this.phone_span_show = false;
          }, 3000);
        }
      }
    },
    wc() {
      if (this.phone) {
        checkSmsForUpdateMobile({
          mobileNumber: this.phone,
          code: this.code
        })
          .then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message.success(res.data.msg);
              this.$router.push({
                name: 'Me'
              });
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.$message.error(err);
            if (err.response) {
              let loginUrl = err.response.headers.loginurl;
              console.log('loginUrl' + loginUrl);
              if (loginUrl) {
                let backUrl = window.location.hash;
                let localBaseIndexOf = loginUrl.indexOf('redirect_uri=');
                if (localBaseIndexOf != -1) {
                  let localBase = loginUrl.substring(
                    localBaseIndexOf + 13,
                    loginUrl.indexOf('oauth2-login')
                  );
                  backUrl = window.location.href.replace(localBase, '');
                }
                // 跳转到系统登录页面
                // debugger
                console.log(
                  loginUrl +
                    '?backUrl=' +
                    encodeURIComponent(encodeURIComponent(backUrl))
                );
                window.location.href =
                  loginUrl +
                  '?backUrl=' +
                  encodeURIComponent(encodeURIComponent(backUrl));
              }
            }
          });
      } else {
        this.$message.warning('请先获取验证码，并输入正确的验证码！');
      }
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

input {
  outline: none;
}

.container {
  width: 100vw;
  height: 178vw;
}

.content {
  padding: 10.68vw 2vw;
  margin-top: 13.47vw;
}

.title {
  width: 100%;
  height: 12.5vw;
  line-height: 12.5vw;
  font-size: 4.8vw;
  color: #fff;
  text-align: center;
  background-color: #da251c;
  position: absolute;
  top: 0;
}
.title span {
  position: absolute;
  left: 2vw;
  font-size: 8vw;
}

.up-li {
  color: #404040;
  font-size: 3.73vw;
  display: flex;
  padding-left: 6.93vw;
  line-height: 10.67vw;
  margin-top: 8vw;
  position: relative;
  border-radius: 2.67vw;
}

#phone_span {
  position: absolute;
  left: 30vw;
  color: red;
  font-size: 3.8vw;
}

#mima_span {
  position: absolute;
  left: 30vw;
}

.yz_text {
  position: absolute;
  right: 15vw;
  top: 1vw;
}
input {
  border: 1px solid #cfcfcf;
  height: 10.67vw;
  width: 62.53vw;
  border-radius: 2.67vw;
  outline: none;
}

.up-span,
.up-hr {
  position: absolute;
  right: 10vw;
}

.up-hr {
  position: absolute;
  top: 25%;
  right: 29vw;
  height: 6vw;
  width: 0.13vw;
  background-color: #a0a0a0;
  border-left: none;
}

.up-span {
  color: #da251c;
  font-size: 3.2vw;
  top: 4%;
}

.footer {
  position: relative;
  top: 13.3vw;
  width: 100%;
  padding: 0 10vw;
}

.end {
  display: block;
  width: 63.3vw;
  height: 6vh;
  background-image: url(../../../static/denglu.png);
  background-size: 100% 100%;
  font-size: 4vw;
  color: #fff;
  text-align: center;
  line-height: 6vh;
  margin-left: 7.7vw;
}
</style>
